import React, { useContext} from 'react';

import './Course.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { courseData } from '../../data/courseData'

function Course() {

    const { theme } = useContext(ThemeContext);
    return (
        <>
            <div className="course" id="course-info" style={{backgroundColor: theme.secondary}}>
                <div className="course-description">
                    <h1 style={{color: theme.tertiary, textAlign:"center"}}>{courseData.title}</h1>
                    {courseData.subtitle && (<h3 style={{color: theme.tertiary, textAlign:"center"}}>({courseData.subtitle})</h3>)}  
                {/* </div>
                <div className="course-body"> */}
                <div className="course-description">
                        <p style={{color:theme.tertiary80}}> {courseData.course.map(desciption => (
                                    <div>{desciption.content}</div>
                                    ))}
                                    
                    <div className="price">
                                    Вартість курсу 4000 грн
                    </div>
                                    </p>
                </div>
                </div>
            </div>
          
        </>
    )
}

export default Course

export const courseData = {
    title : "Програма курсу \"Візуалізація текстилю в програмі Procreate\"",
    subtitle: "уроки записані російською мовою",
    course: [ 
        { content: '1. Знайомство із програмою Procreate. Вивчення основних інструментів'},
        { content: '2. Робота з фото замовника. Від світлокорекції до «ремонту»'},
        { content: '3. Застосування бази моделей візуалізації. Створення ескізу з художнім опрацюванням'},
        { content: '4. Поповнення бази своїми моделями. Де брати? Як створити шаблони для себе?'},
        { content: '5. Деталізація ескізу. Додаємо оздоблення (бахрома, кутаси, мережево, борди)'},
        { content: '6. Шторна стрічка та її промальовка в ескізі'},
        { content: '7. Створення візуалізації поверх старих штор'},
        { content: '8. Накладення текстури тканини. Де взяти текстуру? Створення текстури з фото тканини'},
        { content: '9. Візуалізація простого та складного бандо '},
        { content: '10. Додаємо елементи інтерʼєру в ескіз '},
        { content: '11. Малюємо покривало з декоративними подушками'},
        { content: '12. Обробка візуалізації для соцмереж. Брендування. Створення мудборду'},
    ],
}














// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
import resume from '../assets/pdf/resume.pdf'

export const headerData = {
    name: 'Філіпчук Наталя',
    titleArr: [ 
        { content: 'Художник-візуалізатор'},
        { content:'Викладач курсу'},],
    desciptionArr: [ 
    { content: 'Більше 5 років у візуалізації текстилю'},
    { content:'Досвід роботи текстильним декоратором'},
    { content:'Більше 60 студентів успішно пройшли курс, освоїли нову професію та розширили свій спектр послуг'}],
    // desciption:"Більше 5 років у візуалізації текстилю Досвід роботи текстильним декоратором Більше 60 учнів пройшли навчання за моїм курсом",
    // image: 'https://raw.githubusercontent.com/PhantomScript/asset-container/main/developer-portfolio/landingImg.png',
    // resumePdf: resume
}

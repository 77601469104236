/* eslint-disable */
import girl1 from '../assets/svg/testimonials/girl1.svg'
import girl2 from '../assets/svg/testimonials/girl2.svg'
import girl3 from '../assets/svg/testimonials/girl3.svg'
import boy1 from '../assets/svg/testimonials/boy1.svg'
import boy2 from '../assets/svg/testimonials/boy2.svg'
import boy3 from '../assets/svg/testimonials/boy3.svg'

import img025 from '../assets/jpg/IMG_0025.JPG'
import img026 from '../assets/jpg/IMG_0026.JPG'
import img027 from '../assets/jpg/IMG_0027.JPG'
import img028 from '../assets/jpg/IMG_0028.JPG'
import img029 from '../assets/jpg/IMG_0029.JPG'
import img030 from '../assets/jpg/IMG_0030.JPG'
import img031 from '../assets/jpg/IMG_0031.JPG'
import img032 from '../assets/png/IMG_0032.PNG'
import img033 from '../assets/png/IMG_0033.PNG'
import img034 from '../assets/png/IMG_0034.PNG'
import img035 from '../assets/jpg/IMG_0035.JPG'


export const testimonialsData = [
    {
        id: 1,
        // name: 'Chandler Bing',
        // title: 'Transponster',
        // text: "Building my own portfolio could've never been easier than this. The only areas I'm good at are Math, Jokes and Dance.",
        image: img025
    },
    {
        id: 2,
        image: img026
    },
    {
        id: 3,
        image: img027
    },
    {
        id: 4,
        image: img028
    },
    {
        id: 5,
        image: img029
    },
    {
        id: 6,
        image: img030
    },
    {
        id: 7,
        image: img031
    },
    {
        id: 8,
        image: img032
    },
    {
        id: 9,
        image: img033
    },
    {
        id: 9,
        image: img034
    },
    {
        id: 9,
        image: img035
    }
]


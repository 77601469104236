export const achievementData = {
    bio : "Розміщувати кількісні досягнення в резюме – це чудово, але я просто не працюю з жорсткими цифрами. У мене немає даних, які підтверджують якість моєї роботи. Якщо у вас є великий відповідний досвід, заголовок вашого резюме буде коротким.",
    achievements : [
        {
            id : 1,
            title : 'Google Cloud certification',
            details : 'Сертифікати Google Cloud підтверджують ваш досвід і демонструють вашу здатність трансформувати бізнес за допомогою технології Google Cloud.',
            date : 'Aug 20, 2019',
            field : 'Automation',
            image : 'https://images.unsplash.com/photo-1573141597928-403fcee0e056?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80'
        },
        {
            id : 2,
            title : 'AWS Certification',
            details : 'Перевірте технічні навички та досвід роботи з хмарними ресурсами, щоб розвивати свою кар’єру та бізнес.',
            date : 'Aug 20, 2019',
            field : 'Automation',
            image : 'https://images.unsplash.com/photo-1523474253046-8cd2748b5fd2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },
        {
            id : 3,
            title : 'Professional Data Engineer Certification',
            details : 'Професійні інженери даних дозволяють приймати рішення на основі даних, збираючи, перетворюючи та публікуючи дані.',
            date : 'Aug 20, 2019',
            field : 'Automation',
            image : 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
export const aboutData = {
    title: "Переваги курсу",
    // description1: "Мене звуть Джейн. Я дизайнер із Кр, Укр.",
    // description2: "- записані відео уроки, доступні в будь-який час.",
    descriptionArr: [ 
        { content: '- записані відео уроки, доступні в будь-який час'},
        { content: '- практичні навички та інсайти з власного досвіду'},
        { content: '- викладач з досвідом роботи текстильним декоратором, тобто розуміється на різноманітності тканин, їх властивості та як вони можуть лягати на модель, що допомагає вам ефективніше візуалізувати ваші ідеї'},
        { content:'- разом з курсом ви отримаєте набір готових шаблонів штор та пензликів для візуалізації текстилю'},],
    image: 2
}
import React, { useContext } from 'react';

import { ThemeContext } from '../../contexts/ThemeContext';

import './Education.css'
import EducationCard from './EducationCard';

import { educationData } from '../../data/educationData'

import photo from '../../assets/jpg/IMG_0022.JPG';

function Education() {

    const { theme } = useContext(ThemeContext);
    return (
        <div className="education" id="resume" style={{backgroundColor: theme.secondary}}>
            <div className="education-description">
                <h1 style={{color:theme.tertiary}}>Що потрібно для навчання</h1>
            </div>
           
            <div className="education-body">
                <div className="education-description">
                    {educationData.dataArr.map(edu => (
                        <EducationCard 
                            key={edu.id}
                            id={edu.id}
                            institution={edu.institution}
                            course={edu.course}
                            startYear={edu.startYear}
                            endYear={edu.endYear}
                        />
                    ))}
                </div>
                <div className="education-image">
                    <img src={photo} alt=""/>
                </div>
            </div><div className="education-body">
                <div className="education-description">
                <div className="education-details">  
                <h4 style={{color: theme.tertiary, textAlign:"center"}}>{educationData.description}</h4>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Education

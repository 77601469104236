export const educationData ={
    dataArr: [
        {
            id: 1,
            // institution: 'Marble Hills Middle School',
            course: 'iPad + Apple Pencil',
            // startYear: '2007',
            // endYear: '2015'
        },
        {
            id: 2,
            // institution: 'Somerset School of Science',
            course: 'програма Procreate',
            // startYear: '2015',
            // endYear: '2019'
        },
        {
            id: 3,
            // institution: 'Hawking University, Cambridge',
            course: 'бажання вчитися візуалізувати текстильні ідеї',
            // startYear: '2019',
            // endYear: 'Present'
        },
    ],
    description:"Перетворіть свої навички візуалізації на потужний інструмент для підвищення свого професіоналізму"
}